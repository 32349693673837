<template>
  <div id="app">
    <Game />
  </div>
</template>

<script>
import Game from './components/Game.vue';

export default {
  name: 'Test',
  components: {
    Game,
  },
};
</script>

<style>

</style>
